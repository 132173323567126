import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import {
  accountChangeEmailRequest,
  accountChangeEmailReceive,
  accountLogout,
} from "../actions";
import NavigateButton from "./NavigateButton";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

/**
 * Component for verifying a change of email request.
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {number} user_id - user id to verify
 * @param {string} email - user's new email address if verification passes
 * @param {number} timestamp - timestamp in milliseconds of original request
 * @param {string} signature - signature token of original request
 * @param {object} account_confirm_register_email_response - response from the server
 */
const AccountConfirmNewEmail = ({
  dispatch,
  user_id,
  email,
  timestamp,
  signature,
  account_confirm_register_email_response,
}) => {
  const { t } = useTranslation();

  const [bodyAlert, setBodyAlert] = useState({
    variant: "primary",
    message: "Email verification is in progress.",
    button: null,
  });

  // if proper parameters are present, we can just continue on to posting the verification to the server
  useEffect(() => {
    if (user_id && email && timestamp && signature) {
      dispatch(
        accountChangeEmailRequest({
          user_id,
          email,
          timestamp,
          signature,
        })
      );
    }
  }, [dispatch, user_id, email, timestamp, signature]);

  // subscribe to the response from the server
  useEffect(() => {
    const loginButton = (
      <NavigateButton
        buttonClass="btn btn-primary btn-lg"
        wrapperClass="account-container-btn"
        path="/account/login/"
        buttonText={t("Log In")}
      />
    );
    const continueButton = (
      <NavigateButton
        beforeNavigate={() => dispatch(accountChangeEmailReceive({}))}
        buttonClass="btn btn-primary btn-lg"
        wrapperClass="account-container-btn"
        path="/account/"
        buttonText={t("Continue")}
      />
    );

    const { success, data } = account_confirm_register_email_response || {};

    // if success, then logout so we can log in with the new email
    if (success === true) {
      // navigate("/account/logout/?email=1");
      dispatch(accountLogout());
      setBodyAlert({
        variant: "success",
        message: t(
          "Your new email has been verified. Please log in to continue."
        ),
        button: loginButton,
      });
    }

    // if fail, show message and navigate button
    if (success === false) {
      setBodyAlert({
        variant: "danger",
        message:
          (data && data.detail) || t("There was an issue verifying the email."),
        button: continueButton,
      });
    }
  }, [account_confirm_register_email_response, dispatch, t]);

  return (
    <div className="px-md-4 account-container">
      <Row>
        <Col>
          <h2 className="py-4">
            <FontAwesomeIcon icon={faUserCogReg} className="mr-3" fixedWidth />
            <Trans>Account profile</Trans>
          </h2>
        </Col>
      </Row>
      <Alert variant={bodyAlert.variant}>{bodyAlert.message}</Alert>
      {!!bodyAlert.button && bodyAlert.button}
    </div>
  );
};

AccountConfirmNewEmail.propTypes = {
  dispatch: PropTypes.any,
  user_id: PropTypes.number,
  email: PropTypes.string,
  timestamp: PropTypes.number,
  signature: PropTypes.string,
  account_confirm_register_email_response: PropTypes.object,
};

export default AccountConfirmNewEmail;

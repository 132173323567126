import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import Layout from "../../components/Layout";
import SidebarAccount from "../../components/SidebarAccount";
import AccountConfirmNewEmail from "../../components/AccountConfirmNewEmail";

const VerifyEmail = (props) => {
  const [queryParams] = useQueryParams({
    user_id: NumberParam,
    email: StringParam,
    timestamp: NumberParam,
    signature: StringParam,
  });

  return (
    <Layout pageName="Verify Email">
      <Container fluid>
        <SidebarAccount {...props} />
        <AccountConfirmNewEmail {...props} {...queryParams} />
      </Container>
    </Layout>
  );
};

export default connect((state) => state)(VerifyEmail);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
